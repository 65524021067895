<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.user.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton color="primary" v-on:click="createTableRow" style="margin-right: 10px;">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Staffs')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert v-show="alertMessage" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>
            {{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/staff/list" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'Staffs')">
            <template #email="data">
              <span :title="data.row.email">{{ trimfunction(data.row.email, 50) }}</span>
            </template>
            <template #role="data">
              <span :title="data.row.role">{{ trimfunction(data.row.role, 50) }}</span>
            </template>
            <template #name="data">
              <Avatar :image=data.row.avatar :defaultImage=data.row.avatar :content='data.row.name' :length="20"/>
            </template>

            <template #actions="data">
              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"
                       v-on:click="editTableRow(data.row.id)">
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.view"
                       v-on:click="viewTableRow(data.row.id)">
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete"
                       v-on:click="deletePopup(data.row.id)">
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {staff} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from 'vue-tables-2';
import VueJsonToCsv from "vue-json-to-csv";
import VueSweetalert2 from 'vue-sweetalert2';
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false)
Vue.use(VueForm, options);
export default {
  name: 'UserItem',
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    Avatar
  },
  data() {
    return {
      dismissCountDown: 0,
      alertMessage: '',
      messageColor: 'success',
      self: this,
      json_data: [],
      json_label: {},
      json_title: "",
      columns: ['name', 'email', 'role', 'actions'],
      data: [],
      options: {
        headings: {

          email: this.$lang.user.table.email,
          role: this.$lang.user.table.role,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ['name', 'email', 'mobileNumber', 'city', 'state', 'country', 'type'],
        sortable: ['name', 'email', 'mobileNumber', 'city', 'state', 'country', 'type'],
        filterable: ['name', 'email', 'mobileNumber', 'city', 'state', 'country', 'type']
      },
      module: staff,
    }
  },

  created() {
    let self = this;
    if (localStorage.getItem('notificationMessage') !== "") {
      self.dismissCountDown = 10;
      self.alertMessage = localStorage.getItem('notificationMessage');
      self.messageColor = localStorage.getItem('notificationType');
      localStorage.setItem('notificationMessage', '');
      localStorage.setItem('notificationType', '');
    }
  },

  mounted() {
    self.dismissCountDown = 0;
    // this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {

    editTableRow(item) {
      //  this.$router.push({path: `edit/${(item)}`})
      this.$router.push({name: 'Edit Staff', params: {id: item}})
    },
    viewTableRow(item) {
      this.$router.push({name: 'View Staff', params: {id: item}})
    },
    createTableRow() {
      this.$router.push({name: 'Create Staff'})
    },

    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDown = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDown = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDown = 10;
            self.submitted = false;
          });
        }
      });
    },
    pageChange(val) {
      this.$router.push({query: {page: val}})
    },
    loadData() {
      let self = this;
      axios.get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response.status === 200) {
              self.data = response.data.data;
            }
          });
    },
  }
}
</script>
